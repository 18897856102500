import React, { useState } from "react"
import Layout from "../components/layouts/courseLandingLayout"
import styled from "styled-components"
import PSModal from "../components/modal"
import Button from "../components/buttons/linkButton"
import { Container, Col, Row } from "react-bootstrap"
import Navbar from "../components/navbars/courseLandingNavbarNoMenu"
import ReactGA from "react-ga"
import qa from "../assets/images/qa.png"
import ApplyBox from "../components/applyBox"
import Rich from "../assets/images/rich.jpg"
import IBM from "../assets/images/ibm.png"
import Testimony from "../components/testimony"
import OpenHouseForm from "../components/forms/openHouseForm"
import courseData from "../utils/course-data"

const HeroBackground = styled.div`
  display: block;
  background: none;
  background-size: cover;
  background-position: center center;
  background-color: var(--blue);
`

const HeroContainer = styled(Container)`
  padding-top: 150px;
  padding-bottom: 50px;
  height: 100%;
  h2,
  h3 {
    color: var(--white);
  }
  h5 {
    color: var(--white);
    padding-top: 15px;
    font-family: "roboto-mono";
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
  p {
    color: var(--white);
    padding-top: 20px;
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    h1,
    p {
      color: var(--white);
    }

    .cta-hero-invite {
      margin-bottom: 20px;
    }
  }
`

const VideoSection = styled.section``

const VideoContainer = styled(Container)`
  padding-top: 80px;
  padding-bottom: 60px;
  max-width: 800px;
  h3 {
    text-align: center;
  }
  .cta-container {
    text-align: center;
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
`

const FormSection = styled.section`
  padding-bottom: 80px;
  p {
    padding-top: 20px;
  }
  .btn-apply-curriculum {
    margin-top: 40px;
  }
  .form-container {
    background-color: var(--white);
    padding: 20px;
    margin-top: 40px;
  }
`

const LiveQA = ({ location }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = e => setShow(true)

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "waitlist",
    })
  }

  return (
    <Layout>
      <HeroBackground>
        <Navbar location={location} />
        <PSModal
          handleClose={handleClose}
          handleShow={e => handleShow(e)}
          show={show}
        >
          <OpenHouseForm close={handleClose} align="center" />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col md={6}>
              <h2>Live Open House_</h2>
              <h5>
                {courseData
                  .nextStartDate("upcomingOpenHouses")
                  .format("MMMM D, YYYY @ h:mm A")}
              </h5>
              <p>
                For applicants and serious candidates of Parsity, come meet our
                founder, learn about our program, and have all your questions
                answered.
              </p>
              <Button
                classToAdd={"cta-hero-invite"}
                fireGTMEvent={fireGTMEvent}
                handleCTAClick={e => handleShow(e)}
                text={"Request an Invite"}
              />
            </Col>
            <Col md={6}>
              <img src={qa} alt=""></img>
            </Col>
          </Row>
        </HeroContainer>
      </HeroBackground>
      <VideoSection>
        <VideoContainer>
          <Row>
            <Col className="curriculum-left" md={12}>
              <iframe
                title="open-house"
                width="100%"
                height="435"
                src="https://www.youtube.com/embed/Y9DnLxWlraE"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div className="cta-container">
                <Button
                  fireGTMEvent={fireGTMEvent}
                  handleCTAClick={handleShow}
                  text={"Request an Invite"}
                />
              </div>
            </Col>
          </Row>
        </VideoContainer>
      </VideoSection>
      <FormSection>
        <Container>
          <Row>
            <Col md={6}>
              <div className="form-container">
                <OpenHouseForm close={() => {}} />
              </div>
            </Col>
            <Col md={6}>
              <Testimony
                image={Rich}
                name={"Rich Eldridge, Software Engineer at IBM"}
                text={
                  "I'm two weeks out from graduation, and I have a wonderful job offer with a large tech company! Your mileage may vary, but for me, I nearly doubled my income. Not to mention the other great benefits. And I get to work on cool, cutting edge, technologies. It's literally everything I could have hoped for. If you are considering investing in a code school to help you change careers, I could not recommend Parsity any more highly."
                }
                logo={IBM}
              />
            </Col>
          </Row>
        </Container>
      </FormSection>
      <ApplyBox text="Ready For Your New Career in Tech?" />
    </Layout>
  )
}

export default LiveQA
